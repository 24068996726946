.modal {
    display: block;;
    color: $sec-black;
    background-color:#1e1e1eba;
    &.show {
        display: block;
    }

    .hide {
        display: none;
    }
}