.form-wrapper {
    background-color: $sec-black;
    padding: 15px;
    border-radius: 5px;
}

form {
    background-color: #212429;
    padding: 15px;
    color: $sec-white;

    .form-control,
    .form-select {
        background-color: transparent;
        border: 1px solid #5c5d61;
        border-radius: 4px;
        color: $sec-white;

        &:focus {
            background-color: transparent;
            box-shadow: none;
            color: $sec-white;
            outline: none;
            border: 1px solid $prim-white;

        }

    }

    .error {

        .form-control,
        .form-select {
            border-color: $error;

            &:focus {
                border-color: $error;

            }

            &:focus-visible {
                border-color: $error;

            }
        }

        .error-msg {
            display: block;
        }
    }

    .row {
        margin-bottom: 15px;
    }

    .star {
        color: $error;
    }

    .error-msg {
        color: $error;
        font-size: 12px;
        margin-top: 0px;
        display: block;
        margin-left: 5px;
        display: none;
    }

    .btn {
        background-color: #02519b;
        color: $blue;
        border-radius: 30px;
        padding: 3px 25px;
        float: right;
        margin-bottom: 15px;
        margin-left: 15px;

        &:hover {
            color: $prim-white;
            background-color: #286397;
        }
        &.btn-success {
            background-color: gold; 
            color: $prim-black;  
            border-color: transparent;                  
        }
        &:focus-visible {
            background-color: #085caa;

        }
    }

    // calander icon color changeing
    ::-webkit-calendar-picker-indicator {
        filter: invert(.6);
    }

    .form-check-input {
        background-color: transparent;

        &[type=radio] {

            &:checked {
                background-color: #EFF0F4;
                border-color: transparent;
            }
        }



        &[type=checkbox] {
            border-radius: 0;


        }


        &:focus {
            box-shadow: none;
        }
    }

    .checkBox {
        width: auto;
    }

    .checkBoxWrapper {
        padding-left: 15px;
    }

    #employeeImg {
        width: 100px;
        height: 100px;
        border: 1px solid $sec-white;
    }
    .form-label {
        white-space: nowrap;
    }


}

#permanentAddress,
#peresentAddress,
#personalInformation,
#qualificationSkills,
#healthDetails,
#emergencyContact1,
#emergencyContact2,
#emergencyContact3 {
    // display: none;

}